<template>
    <div class="d-flex w-100 justify-content-end mt-6">
        <st-button
            variant="primary"
            :callback="previewDocument"
            v-if="showPreviewBtn"
            :spinner="isPreviewLoading"
        >
            <i class="far fa-file"></i>{{$t('INTERNAL_DOCUMENTS.FORM.PREVIEW_DOC')}}
        </st-button>

        <st-button
            variant="light"
            class="ml-4 st-btn-text-primary"
            v-if="showDrafBtn"
            :callback="saveAsDraft"
            :spinner="isSaveLoading"
            :disabled="isDraftDisabled">
            <i class="fas fa-save"></i>{{$t('INTERNAL_DOCUMENTS.FORM.SAVE_AS_DRAFT_BUTTON')}}
        </st-button>

        <st-button variant="primary"
            :callback="generateDocument"
            v-if="showGenerateBtn"
            class="ml-4">
            <i class="far fa-file"></i>{{$t('INTERNAL_DOCUMENTS.VIEW.GENERATE_DOCUMENT_BTN')}}
        </st-button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'InternalDocumentsDetailsToolbar',
    data() {
        return {
            userActionsStatus: {
                PROCESSING: 'PROCESSING',
                RESOLVED: 'RESOLVED'
            },
            userActionsTypes: {
                DOCUMENT_GENERATION_REQUEST: 'DOCUMENT_GENERATION_REQUEST',
                SIGN_DOCUMENT: 'SIGN_DOCUMENT',
                APPLICATION_REVIEW: 'APPLICATION_REVIEW'
            },
            disableDraftStatus: ['sign_pending', 'closed'],
            hidePreviewStatus: ['new', 'closed'],
            fileType: {
                OUTPUT: 'output',
                INPUT: 'input'
            }
        }
    },
    computed: {
        ...mapGetters({
            userActions: "internalDocuments/form/userActions",
            record: "internalDocuments/form/record",
            currentUser: "auth/currentUser",
            loading: "shared/loading",
        }),
        isDocumentApproved() {
            return this.record.internal_status === 'approved';
        },
        showGenerateBtn() {
            if (!this.generateDocumentActions.length) {
                return false;
            }

            return !this.isDocumentGenerated
                && this.isDocumentApproved
                && this.record.assigned_staff_user_id
                && this.isCurrentUserAssigned;
        },
        showPreviewBtn() {
            return !this.hidePreviewStatus.includes(this.record.internal_status)
                && this.isCurrentUserAssigned
                && !this.isDocumentGenerated;
        },
        showDrafBtn() {
            const applicationReviewAction = this.userActions.filter(
                (el) => el.action_type === this.userActionsTypes.APPLICATION_REVIEW && el.status === this.userActionsStatus.PROCESSING);

            return applicationReviewAction;
        },
        isDraftDisabled() {
            if (this.isDocumentGenerated) {
                return true;
            } else {
                return this.disableDraftStatus.includes(this.record.internal_status)
                    || !this.record.assigned_staff_user_id
                    || this.record.assigned_staff_user_id !== this.currentUser.user_id;
            }
        },
        generateDocumentActions() {
            return this.userActions.filter(
                (el) => el.action_type === this.userActionsTypes.DOCUMENT_GENERATION_REQUEST &&
                el.file_type === this.fileType.OUTPUT
            );
        },
        isDocumentGenerated() {
            return !!this.generateDocumentActions.some((el) => el.status === this.userActionsStatus.RESOLVED && el.file_type === this.fileType.OUTPUT);
        },
        isCurrentUserAssigned() {
            return this.record.assigned_staff_user_id === this.currentUser.user_id;
        },
        isSaveLoading() {
            return this.loading['internalDocuments/update'];
        },
        isPreviewLoading() {
            return this.loading['internalDocuments/previewDocument'];
        },
    },
    methods: {
        previewDocument() {
            this.$emit('previewDocument');
        },
        saveAsDraft() {
            this.$emit('saveAsDraft', true);
        },
        generateDocument() {
            this.$emit('generateDocument');
        },
    },
};
</script>
