import i18n from '@/shared/plugins/vue-i18n';
import GenericModel from '@/shared/models/generic-model';
import { StaffUsersField } from '@/modules/administrator/fields/staff-users-field';

const field = (name) => i18n.t(`INSTITUTION.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`INSTITUTION.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    staff_users: StaffUsersField.relationToOne(
        'staff_users',
        field('staff_users'),
        { autocompleteRequired: true },
        { placeholder: placeholder('staff_users') },
        [],
        { asyncData: true, hasPagination: true }
    ),
};

export class FunctionaryUserModel extends GenericModel {
    static get fields() {
        return fields;
    }
};
