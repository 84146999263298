<template>
    <div class="d-flex align-content-right justify-content-end">
        <st-button
            variant="light"
            class="st-btn-text-primary"
            v-if="showPreSignButton"
            :callback="signDocument"
        >
            <i class="fa fa-save"></i>
            {{ $t("INTERNAL_DOCUMENTS.VIEW.SIGN_DOCUMENT") }}
        </st-button>

        <st-button 
            variant="primary" 
            :callback="generateDocument" 
            v-if="showGenerateBtn">
            <i class="far fa-file"></i>{{$t('INTERNAL_DOCUMENTS.VIEW.GENERATE_DOCUMENT_BTN')}}
        </st-button>

    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Message from "@/shared/message/message";

export default {
    name: "InternalDocumentsApplicationDetailsToolbar",
    data() {
        return {
            userActionsStatus: {
                PROCESSING: 'PROCESSING',
                RESOLVED: 'RESOLVED'
            },
            resolvedUserActions: []
        }
    },
    computed: {
        ...mapGetters({
            record: "internalDocuments/form/record",
            userActions: "internalDocuments/form/userActions",
            currentUser: "auth/currentUser",
        }),
        showPreSignButton() {
            return this.preSignDocumentAction && this.currentUser.user_id === this.record.created_by;
        },
        generateDocumentActions() {
            return this.userActions.filter((el) => el.action_type === 'DOCUMENT_GENERATION_REQUEST');
        },
        isDocumentGenerated() {
            return !!this.generateDocumentActions.some((el) => el.status === this.userActionsStatus.RESOLVED);
        },
        preSignDocumentAction() {
            return this.userActions.find((el) => el.action_type === 'PRE_SIGN_DOCUMENT' && el.status === this.userActionsStatus.PROCESSING);
        },
        showGenerateBtn() {
            if (!this.generateDocumentActions.length) {
                return false;
            }

            return !this.isDocumentGenerated && this.record.created_by === this.currentUser.user_id
        }
    },
    methods: {
        signDocument() {
            if (this.preSignDocumentAction) {
                this.$emit('signDocument', this.preSignDocumentAction.id);
            } else {
                Message.error('INTERNAL_DOCUMENTS.VIEW.SIGN_DOCUMENT_ERROR');
            }
        },
        async generateDocument() {
            this.$emit('generateDocument');
        }
    },
};
</script>
