<template>
    <st-section
        :header="$t('INTERNAL_DOCUMENTS.FORM.DOCUMENT_TYPE_HEADER')"
        id="internal-document-type-form"
    >
        <template #body>
            <form class="form"
                  novalidate="novalidate"
                  id="st_internal_document_type_form"
                  ref="st_internal_document_type_form"
              >
                <div class="form-group">
                    <label>
                        {{ fields.application_type_id.label }} *
                    </label>
                    <st-select
                        v-model="model[fields.application_type_id.name]"
                        :ref="fields.application_type_id.name"
                        :field="fields.application_type_id"
                        :formValidation="fv"
                        :autoSelectFirstOption="autoSelectFirstOption"
                        :disabled="viewMode"
                    />
                </div>

                <div class="form-group" v-if="showApplication">
                    <label>{{$t('INTERNAL_DOCUMENTS.FIELDS.APPLICATION_NUMBER')}}</label>
                    <st-search
                        v-model="selectedApplication"
                        :options="options"
                        ref="selectedApplication"
                        name="selectedApplication"
                        :searchFnc="onInputChange"
                        :searchText="$t('INTERNAL_DOCUMENTS.FORM.SEARCH_FOR')"
                        :disabled="disableApplication"
                        ></st-search>
                </div>
            </form>
        </template>
    </st-section>
</template>

<script>
import { FormSchema } from '@/shared/form/form-schema';
import { createFormValidation } from '@/shared/utils/create-form-validation';

import { mapActions, mapGetters } from "vuex";
import { InternalDocumentsModel } from '@/modules/internal-documents/models/internal-documents-model';

const { fields } = InternalDocumentsModel;

const formSchema = new FormSchema([
    fields.application_type_id,
]);
export default {
    name: "InternalDocumentTypeForm",
    data() {
        return {
            rules: formSchema.rules(),
            model: {},
            fields,
            fv: null,
            minValueLength: 2,
            applications: [],
            selectedApplication: null,
            selectedApplicationIdentificationNumber: null,
            autoSelectFirstOption: false,
        };
    },
    props: {
        viewMode: {
            type: Boolean,
            default: true,
        },
    },
    watch: {
        model: {
            deep: true,
            handler(val) {
                this.$emit('change', val);
            },
        },
    },
    computed: {
        ...mapGetters({
            record: 'internalDocuments/form/record',
            currentUser: 'auth/currentUser',
        }),
        options() {
            return this.applications;
        },
        disableApplication() {
            return this.viewMode || !!this.$route.query?.appId;
        },
        showApplication() {
            return !!this.$route.query?.appId;
        }
    },
    methods: {
        ...mapActions({
            doFindApplications: "internalDocuments/form/findApplications",
            doFind: "internalDocuments/form/findApplication",
        }),
        async onInputChange(value) {
            if (value.length > this.minValueLength){
                const applications = await this.doFindApplications({
                    params: {
                        limit: 25,
                        query: `identification_number contains \'${value}\'` 
                    }
                });

                return applications.data.map((row) => ({
                    value: row.id,
                    text: row.identification_number,
                    application: row,
                }));
            }
        },
    },
    async created() {
        this.model = formSchema.initialValues(this.record);
        
        if (this.$route.query?.appTypeId) {
            this.autoSelectFirstOption = false;
            this.model[fields.application_type_id.name] = this.$route.query.appTypeId;
        } else {
            this.autoSelectFirstOption = this.record ? false : true;
        }

        if (this.$route.query?.appId) {
            const selectedApplication = await this.doFind(this.$route.query.appId);
            if (selectedApplication) {
                this.selectedApplication = selectedApplication.id;
                this.selectedApplicationIdentificationNumber = selectedApplication.identification_number;
                this.applications.push({
                    value: selectedApplication.id,
                    text: selectedApplication.identification_number,
                    application: selectedApplication
                });
            }
        }
    },
    async mounted() {
        if (!this.viewMode) {
            this.fv = createFormValidation('st_internal_document_type_form', this.rules);
        }
    },
}
</script>
