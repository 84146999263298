<template>
    <div>
        <st-button
            v-if="canUpload"
            variant="primary"
            :callback="addDocument"
            class="ml-6"
        >
            <i class="fa fa-plus"></i>
            {{ $t('INTERNAL_DOCUMENTS.FORM.ADD_DOCUMENT') }}
        </st-button>

        <st-button
            variant="primary"
            :callback="refresh"
            class="ml-6"
        >
            <i class="fas fa-sync"></i>
            {{ $t('INTERNAL_DOCUMENTS.FORM.REFRESH_DOCUMENT') }}
        </st-button>

        <documents-upload-modal
            ref="documents-upload-modal"
            :docId="docId"
        >
        </documents-upload-modal>

    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    //import { ControlPermissions } from "@/modules/control/control-permissions";

    export default {
        name: 'DocumentsListToolbar',
        components: {
            DocumentsUploadModal: () => import("./DocumentsUploadModal.vue"),
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters({
                currentUser: 'auth/currentUser',
                record: 'internalDocuments/form/record'
            }),
            docId() {
                return this.record.id;
            },
            canUpload() {
                return true; //(this.hasPermissionToReadStaff && !['closed'].includes(this.record?.status));
            },
            hasPermissionToReadStaff() {
                return true//;new ControlPermissions(this.currentUser).read;
            },
        },
        methods: {
            ...mapActions({
                doFind: "internalDocuments/form/find"
            }),
            addDocument() {
                this.$refs['documents-upload-modal'].show();
            },
            refresh() {
                setTimeout(async () => await this.doFind(this.record.id), 500);
            }
        },
    };
</script>