<template>
    <st-page :showHeader="false" class="mt-6">
        <documents-list-filter>
            <template #toolbar>
                <documents-list-toolbar />
            </template>

            <documents-list-table />
        </documents-list-filter>
    </st-page>
</template>
<script>
import DocumentsListFilter from './DocumentsListFilter';
import DocumentsListTable from './DocumentsListTable';
import DocumentsListToolbar from './DocumentsListToolbar';

export default {
    name: 'DocumentsList',
    components: {
        DocumentsListFilter,
        DocumentsListTable,
        DocumentsListToolbar,
    },
}
</script>