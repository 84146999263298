<template>
    <div class="mt-6">
        <Loader v-if="showLoader" />

        <internal-documents-application-details-toolbar
            @generateDocument="generateDocument"
            @signDocument="signDocument"
        />

        <confirm-sign-document-modal
            :id="internalDocumentId"
            ref="confirm-sign-document-modal"
            @signed="internalDocumentSync"/>

        <internal-document-type-form
            ref="internalDocumentTypeForm"
            @change="doSelectInternalDocumentType"
            :viewMode="viewMode"
        />

        <generate-form
            v-if="formSchema"
            :data="formSchema"
            :value="formData"
            ref="generateForm"
            @modelUpdated="updateFormData"
            :viewMode="viewMode"
        />

        <div class="d-flex justify-content-center mt-6">
            <st-button
                variant="primary"
                v-if="!isSingleDocument"
                :disabled="viewMode"
                :spinner="saveLoading"
                :callback="saveInternalDocument">
                {{ $t('INTERNAL_DOCUMENTS.FORM.SAVE_BTN') }}
            </st-button>

            <st-button
                variant="primary"
                v-if="isSingleDocument"
                :callback="createInternalDocument">
                {{ $t('INTERNAL_DOCUMENTS.FORM.CONTINUE_BTN') }}
            </st-button>
        </div>
    </div>
</template>

<script>
import InternalDocumentTypeForm from '@/modules/internal-documents/components/InternalDocumentTypeForm.vue'
import InternalDocumentsApplicationDetailsToolbar from '@/modules/internal-documents/components/application-details/InternalDocumentsApplicationDetailsToolbar.vue';
import GenerateForm from "@/modules/forms/components/generate-form/GenerateForm.vue";
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
import Message from "@/shared/message/message";
import ConfirmSignDocumentModal from "@/modules/internal-documents/components/ConfirmSignDocumentModal.vue";

export default {
    name: 'InternalDocumentsApplicationDetails',
    components: {
        InternalDocumentTypeForm,
        GenerateForm,
        InternalDocumentsApplicationDetailsToolbar,
        ConfirmSignDocumentModal
    },
    props: {},
    data() {
        return {
            formSchema: null,
            formData: {},
            isSingleDocument: null,
            polling: null,
            statusProcessingExists: true,
            actionsLoading: false,
            resolvedUserActions: [],
            userActionsStatus: {
                PROCESSING: 'PROCESSING',
                RESOLVED: 'RESOLVED'
            },
            signLoading: false,
            saveLoading: false
        }
    },
    computed: {
        ...mapGetters({
            record: "internalDocuments/form/record",
            userActions: "internalDocuments/form/userActions",
            currentUser: "auth/currentUser",
            userActionsLoading: "internalDocuments/form/userActionsLoading",
        }),
        viewMode() {
            if (!this.record) {
                return false;
            }

            return this.record?.status !== 'draft' || this.record?.applicant_user_id !== this.currentUser.user_id || this.isDocumentGenerated;
        },
        internalDocumentId() {
            return this.record?.id || '';
        },
        generateDocumentActions() {
            return this.userActions.filter((el) => el.action_type === 'DOCUMENT_GENERATION_REQUEST');
        },
        isDocumentGenerated() {
            return !!this.generateDocumentActions.some((el) => el.status === this.userActionsStatus.RESOLVED);
        },
        showLoader() {
            return this.actionsLoading || this.signLoading || this.saveLoading || this.userActionsLoading;
        }
    },
    methods: {
        ...mapActions({
            doCreate: "internalDocuments/form/create",
            doUpdate: "internalDocuments/form/update",
            doFindInternalDocumentType: "internalDocuments/form/findInternalDocumentType",
            doFind: "internalDocuments/form/find",
            doFindForm: "internalDocuments/form/findForm",
            doUpdateApplication: "internalDocuments/form/updateApplication",
            doFindApplication: "internalDocuments/form/findApplication",
            doGenerateDocument: "internalDocuments/form/generateFinalDocument",
            signDoc: "internalDocuments/form/signDocument",
            doFindUserActions: "internalDocuments/form/findUserActions",
            doResetUserActions: "internalDocuments/form/doResetUserActions",
            resetUserActions: "internalDocuments/form/doResetUserActions",
        }),
        async doSelectInternalDocumentType({ application_type_id }) {
            if (application_type_id) {
                const internalDocumentType = await this.doFindInternalDocumentType(application_type_id);
                this.isSingleDocument = !!(!internalDocumentType.form_citizen_id && internalDocumentType.form_staff_id);
                this.formSchema = null;

                if (!this.isSingleDocument) {
                    this.doFindForm(internalDocumentType.form_citizen_id).then((form) => {
                        this.formSchema = form.schema;
                    });
                }
            }
        },
        updateFormData(data, key) {
            Vue.set(this.formData, key, data);
        },
        async createInternalDocument() {
            const data = await this.formIsValid();
            if (data) {
                const internalDocument = await this.doCreate(data);

                if (data.parent_application_id) {
                    //update parent application
                    const parentApplication = await this.doFindApplication(data.parent_application_id);
                    if (parentApplication) {
                        const relatedApplications = parentApplication.related_application_ids;
                        relatedApplications.push(internalDocument.id);

                        await this.doUpdateApplication({
                            id: data.parent_application_id,
                            values: {
                                related_application_ids: relatedApplications
                            }
                        })
                    }
                }

                this.$router.push({
                    name: 'internalDocumentEdit',
                    params: {
                        id: internalDocument.id
                    }
                })
            }
        },
        async saveInternalDocument(redirect = true) {
            const values = await this.formIsValid();
            if (values) {
                this.saveLoading = true;
                if (!this.isSingleDocument) {
                    const generateFormData = await this.$refs.generateForm.getData(true);
                    values.form = {
                        ...generateFormData.form,
                        options: generateFormData.options
                    }

                    values.form_staff = values.form;
                }

                let internalDocument = null;
                if (this.record) {
                    internalDocument = await this.doUpdate({
                        id: this.record.id,
                        values
                    });
                } else {
                    internalDocument = await this.doCreate(values);
                }

                this.saveLoading = false;

                if (redirect) {
                    this.doResetUserActions();
                    await this.getUserActions();

                    this.$router.push({
                        name: 'internalDocumentEdit',
                        params: {
                            id: internalDocument.id
                        }
                    })
                }
            }
        },
        async formIsValid() {
            const internalDocumentForm = this.$refs.internalDocumentTypeForm;
            const validForm = await internalDocumentForm.fv.validate();
            let data = null;

            if (validForm) {
                const application_type_id = internalDocumentForm.model.application_type_id;
                data = {
                    application_type_id,
                    application_type_name: internalDocumentForm.fields.application_type_id.options.find((el) => el.value === application_type_id).text,
                }

                if (internalDocumentForm.selectedApplication) {
                    data.parent_application_id = internalDocumentForm.selectedApplication;
                    data.parent_application_identification_number = internalDocumentForm.selectedApplicationIdentificationNumber;
                }
            }

            return data;
        },
        async generateDocument() {
            const generateDocumentAction = this.userActions.find((el) => el.status === 'PROCESSING');
            if (generateDocumentAction) {
                try {
                    await this.saveInternalDocument(false);
                    await this.doGenerateDocument({ id: this.record.id, action: generateDocumentAction.id});
                    await this.doFind(this.record.id);

                    this.polling = setInterval(async () => {
                        await this.processUserActions();
                    }, 2000);

                    Message.success('INTERNAL_DOCUMENTS.VIEW.GENERATE_DOCUMENT_MESSAGE');
                } catch (error) {
                    if (error === 'VALIDATION_ERROR') {
                        this.$alert({
                            type: 'warning',
                            text: this.$t('FORMS.MESSAGE.VALID_ERROR'),
                            showConfirmButton: false,
                        });
                    } else {
                        Message.error('INTERNAL_DOCUMENTS.VIEW.GENERATE_DOCUMENT_ERROR');
                    }
                }
            } else {
                Message.error('INTERNAL_DOCUMENTS.VIEW.GENERATE_DOCUMENT_ERROR');
            }
        },
        signDocument(actionId) {
            if (actionId) {
                this.signLoading = true;

                this.signDoc({ id: this.record.id, action: actionId}).then(() => {
                    this.$refs['confirm-sign-document-modal'].show();
                    clearInterval(this.polling);
                    this.signLoading = false;
                }).catch(() => {
                    this.signLoading = false;
                    Message.error('INTERNAL_DOCUMENTS.VIEW.SIGN_DOCUMENT_ERROR');
                });
            } else {
                Message.error('INTERNAL_DOCUMENTS.VIEW.SIGN_DOCUMENT_ERROR');
            }
        },
        async processUserActions() {
            if (this.$route.params?.id) {
                try {
                    this.actionsLoading = true;
                    await this.getUserActions();

                    if (this.statusProcessingExists.length !== 0) {
                        this.actionsLoading = false;
                        clearInterval(this.polling);

                        //get updated application data
                        await this.doFind(this.$route.params.id);
                    }
                } catch (error) {
                    clearInterval(this.polling);
                    Message.error(error);
                }
            }
        },
        async getUserActions(isSync = false) {
            try {
                const paramsResolved = {
                    query: `application_id eq ${this.record.id} and status eq ${this.userActionsStatus.RESOLVED}`,
                    sort: '-updated_date'
                };
                this.resolvedUserActions = await this.doFindUserActions(paramsResolved);

               if (!isSync) {
                    const paramsProcessing = {
                        query: `application_id eq ${this.record.id} and status eq ${this.userActionsStatus.PROCESSING}`,
                        sort: '-created_date'
                    };
                    this.statusProcessingExists = await this.doFindUserActions(paramsProcessing);
                }
            } catch(error) {
                if (this.polling) {
                    clearInterval(this.polling);
                }
            }
        },
        internalDocumentSync() {
            if (!this.polling) {
                this.resetUserActions();
                this.polling = setInterval(async () => {
                    await this.syncUserActions();
                }, 2000);
            }
        },
        async syncUserActions() {
            try {
                this.actionsLoading = true;

                await this.getUserActions(true);
                if (this.isSignedByCurrentUser()) {
                    this.actionsLoading = false;
                    await this.doFind(this.$route.params.id);
                    clearInterval(this.polling);
                }
            } catch (error) {
                clearInterval(this.polling);
            }
        },
        isSignedByCurrentUser() {
            if (!this.resolvedUserActions.length) return false;
            const resolvedSignActions = this.resolvedUserActions.find((el) => el.action_type === 'PRE_SIGN_DOCUMENT');
            return !!resolvedSignActions;
        },
    },
    async created(){
        if (this.record) {
            this.formData = this.record.form;

            if (this.record.has_internal_input_doc) {
                const internalDocumentType = await this.doFindInternalDocumentType(this.record.application_type_id);
                this.doFindForm(internalDocumentType.form_citizen_id).then((form) => {
                    this.formSchema = form.schema;
                });

                this.isSingleDocument = false;
            }

            this.doResetUserActions();
            await this.getUserActions();
        }
    },
    beforeDestroy () {
        if (this.polling) {
            clearInterval(this.polling);
        }
    }
}
</script>
