<template>
    <st-page :duplicateToolbar="true">
        <template #toolbar>
            <internal-documents-details-toolbar
                @previewDocument="previewDocument"
                @saveAsDraft="saveAsDraft"
                @generateDocument="generateDocument"
            />
        </template>

        <generate-form
            v-if="formSchema"
            :data="formSchema"
            :value="formData"
            ref="generateForm"
            @modelUpdated="updateFormData"
            :viewMode="viewMode"
        />

        <Loader v-if="actionsLoading" />
    </st-page>
</template>

<script>
import GenerateForm from "@/modules/forms/components/generate-form/GenerateForm.vue";
import InternalDocumentsDetailsToolbar from "@/modules/internal-documents/components/details/InternalDocumentsDetailsToolbar.vue";
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
import Message from '@/shared/message/message';

export default {
    name: 'InternalDocumentsDetails',
    props: {},
    components: {
        GenerateForm,
        InternalDocumentsDetailsToolbar,
    },
    data() {
        return {
            formSchema: null,
            formData: {},
            userActionsStatus: {
                PROCESSING: 'PROCESSING',
                RESOLVED: 'RESOLVED'
            },
            previewLoading: false,
            generateDocumentActionName: 'DOCUMENT_GENERATION_REQUEST',
            actionsLoading: false
        }
    },
    computed: {
        ...mapGetters({
            record: "internalDocuments/form/record",
            userActions: "internalDocuments/form/userActions",
            currentUser: "auth/currentUser",
        }),
        viewMode() {
            return this.record?.status === 'closed' || this.record.assigned_staff_user_id !== this.currentUser.user_id || this.isDocumentGenerated;
        },
        generateDocumentAction() {
            return this.userActions.find((el) => el.action_type === this.generateDocumentActionName);
        },
        isDocumentGenerated() {
            return this.generateDocumentAction?.status === this.userActionsStatus.RESOLVED;
        }
    },
    methods: {
        ...mapActions({
            doFindForm: "internalDocuments/form/findForm",
            doFindDocumentType: "internalDocuments/form/findInternalDocumentType",
            doResetUserActions: "internalDocuments/form/doResetUserActions",
            doFindUserActions: "internalDocuments/form/findUserActions",
            doPreviewDocument: "internalDocuments/form/previewDocument",
            doUpdate: "internalDocuments/form/update",
            doGenerateDocument: "internalDocuments/form/generateFinalDocument",
            doFind: "internalDocuments/form/find",
        }),
        updateFormData(data, key) {
            Vue.set(this.formData, key, data);
        },
        async getUserActions() {
            const paramsResolved = {
                query: `application_id eq ${this.record.id} and status eq ${this.userActionsStatus.RESOLVED}`,
                sort: '-created_date'
            };
            const paramsProcessing = {
                query: `application_id eq ${this.record.id} and status eq ${this.userActionsStatus.PROCESSING}`,
                sort: '-created_date'
            };

            await this.doFindUserActions(paramsResolved);
            this.statusProcessingExists = await this.doFindUserActions(paramsProcessing);
        },
        async previewDocument() {
            this.previewLoading = true;

            await this.saveAsDraft(true);

            const { data, downloadAccessToken } = await this.doPreviewDocument({ id: this.record.id});

            this.previewLoading = false;

            window.open(`${window.VUE_APP_API_URL}/files/${data.file_id}?token=${downloadAccessToken}`, '_blank');
        },
        async saveAsDraft(asDraft) {
            if (!this.$refs.generateForm) {
                return null;
            }

            const genFormData = await this.$refs.generateForm.getData(asDraft);
            const formData = {
                form_staff: {
                    ...genFormData.form,
                    options: genFormData.options,
                }
            }

            try {
                await this.doUpdate({
                    id: this.record.id,
                    values: formData
                });
            } catch (error) {
                Message.error('INTERNAL_DOCUMENTS.VIEW.SAVE_FAILED');
            }
        },
        async generateDocument() {
            if (this.generateDocumentAction) {
                try {
                    await this.saveAsDraft(false);
                    await this.doGenerateDocument({ id: this.record.id, action: this.generateDocumentAction.id});

                    this.polling = setInterval(async () => {
                        await this.processUserActions();
                    }, 1000);

                    Message.success('INTERNAL_DOCUMENTS.VIEW.GENERATE_DOCUMENT_MESSAGE');
                } catch (error) {
                    if (error === 'VALIDATION_ERROR') {
                        this.$alert({
                            type: 'warning',
                            text: this.$t('FORMS.MESSAGE.VALID_ERROR'),
                            showConfirmButton: false,
                        });
                    } else {
                        Message.error('INTERNAL_DOCUMENTS.VIEW.GENERATE_DOCUMENT_ERROR');
                    }
                }
            } else {
                Message.error('INTERNAL_DOCUMENTS.VIEW.GENERATE_DOCUMENT_ERROR');
            }
        },
        async processUserActions() {
            if (this.$route.params?.id) {
                try {
                    this.actionsLoading = true;
                    await this.getUserActions();
                    if (this.statusProcessingExists.length !== 0) {
                        this.actionsLoading = false;
                        this.resetPolling();

                        //get updated application data
                        await this.doFind(this.$route.params.id);
                    }
                } catch (error) {
                    this.resetPolling();
                    Message.error(error);
                }
            }
        },
        resetPolling() {
            clearInterval(this.polling);
            this.polling = null;
        }
    },
    async created() {
        if (this.record) {
            const documentType = await this.doFindDocumentType(this.record.application_type_id);
            const form = await this.doFindForm(documentType.form_staff_id);

            this.formSchema = form.schema;
            this.formData = this.record?.form_staff || {};

            //find user actions related to application
            this.doResetUserActions();
            await this.getUserActions();
        }
    }
}
</script>
