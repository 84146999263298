<template>
    <div>
        <Loader v-if="actionsLoading" />

        <st-button
            variant="light"
            class="st-btn-text-danger"
            :callback="reassignStaff"
            v-if="showReassignButton"
        >
            <i class="fa fa-user"></i>
            {{ $t("INTERNAL_DOCUMENTS.VIEW.REASSIGN_STAFF") }}
        </st-button>

        <st-button
            v-if="showAssignButton"
            variant="light"
            class="st-btn-text-danger"
            :callback="reassignStaff"
        >
            <i class="fa fa-user"></i>
            {{ $t("INTERNAL_DOCUMENTS.VIEW.ASSIGN_STAFF") }}
        </st-button>

        <st-button
            variant="light"
            class="st-btn-text-success"
            v-if="showApproveReject"
            :callback="approveDocument"
            :spinner="isApproveLoading"
        >
            <i class="fas fa-check"></i>
            {{ $t("INTERNAL_DOCUMENTS.VIEW.APPROVE_DOCUMENT") }}
        </st-button>

        <st-button
            variant="light"
            class="st-btn-text-danger"
            v-if="showApproveReject"
            :callback="rejectDocument"
        >
            <i class="fas fa-times"></i>
            {{ $t("INTERNAL_DOCUMENTS.VIEW.REJECT_DOCUMENT") }}
        </st-button>

        <st-button
            variant="light"
            class="st-btn-text-primary"
            v-if="showSignButton"
            :callback="signDocument"
            :disabled="signLoading"
            :spinner="signLoading"
        >
            <i class="fa fa-save"></i>
            {{ $t("INTERNAL_DOCUMENTS.VIEW.SIGN_DOCUMENT") }}
        </st-button>

        <st-button
            variant="danger"
            :callback="returnDocument"
            v-if="showReturnButton"
        >
            <i class="fa fa-times"></i>
            {{ $t("INTERNAL_DOCUMENTS.VIEW.RETURN_DOCUMENT") }}
        </st-button>

        <confirm-sign-document-modal
            :id="internalDocumentId"
            ref="confirm-sign-document-modal"
            @signed="internalDocumentSync"/>

        <response-notification-modal
            :id="internalDocumentId"
            @reject="rejectInternalDocument"
            ref="response-notification-modal"/>

        <assign-staff-modal
            ref="assignStaffModal"
            :id="internalDocumentId"
            @refreshActions="refreshActions"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ConfirmSignDocumentModal from "./ConfirmSignDocumentModal";
import Message from "@/shared/message/message";
import ResponseNotificationModal from "./ResponseNotificationModal";
import AssignStaffModal from "./AssignStaffModal";
//import { ApplicationsPermissions } from '@/modules/applications/applications-permissions';

export default {
    name: "InternalDocumentsViewToolbar",
    components: {
        ConfirmSignDocumentModal,
        ResponseNotificationModal,
        AssignStaffModal,
    },
    data() {
        return {
            actionsLoading: false,
            statusProcessingExists: true,
            polling: null,
            userActionsStatus: {
                PROCESSING: 'PROCESSING',
                RESOLVED: 'RESOLVED'
            },
            userActionsTypes: {
                DOCUMENT_GENERATION_REQUEST: 'DOCUMENT_GENERATION_REQUEST',
                SIGN_DOCUMENT: 'SIGN_DOCUMENT',
                APPLICATION_REVIEW: 'APPLICATION_REVIEW'
            },
            canSignStatus: ['pending', 'approved', 'sign_pending'],
            isApproveLoading: false,
            signLoading: false,
            resolvedUserActions: [],
            fileType: {
                OUTPUT: 'output',
                INPUT: 'input'
            }
        }
    },
    computed: {
        ...mapGetters({
            record: "internalDocuments/form/record",
            userActions: "internalDocuments/form/userActions",
            currentUser: "auth/currentUser",
            accountDetails: "auth/accountDetails",
        }),
        hideAssignButton() {
            return this.record?.assigned_staff_user_id;
        },
        showSignButton() {
            //assigned user id from sign action should correspond with current user id or
            //user function should correspond with the sign action function
            const signAction = this.getCurrentSignAction();

            let isUserAssigned = false;
            let userHasFunction = false;

            if (signAction) {
                if (signAction?.user_id) {
                    isUserAssigned = signAction.user_id === this.currentUser.user_id;
                }

                if (signAction?.function_type) {
                    userHasFunction = !!this.accountDetails?.functions.includes(signAction.function_type);
                }

            }

            return (this.canSignStatus.includes(this.record?.internal_status)) && (isUserAssigned || userHasFunction);
        },
        showApproveReject() {
            return this.hideAssignButton
                && (this.record.internal_status === "pending" || this.record.internal_status === "review")
                && this.record.assigned_staff_user_id === this.currentUser.user_id
                && this.reviewDocumentAction?.length
        },
        showReviewBtn() {
            return this.hideAssignButton
                && (this.record.internal_status === "pending")
                && this.record.assigned_staff_user_id === this.currentUser.user_id
                && !this.signDocumentActions?.length
        },
        internalDocumentId() {
            return this.record?.id || '';
        },
        signDocumentActions() {
            return this.userActions.filter((el) => el.action_type === 'SIGN_DOCUMENT');
        },
        generateDocumentActions() {
            return this.userActions.filter((el) => el.action_type === 'DOCUMENT_GENERATION_REQUEST' && el.file_type === this.fileType.OUTPUT);
        },
        reviewDocumentAction(){
            return this.userActions.filter((el) => el.action_type === 'APPLICATION_REVIEW' && el.status === this.userActionsStatus.PROCESSING);
        },
        showReturnButton() {
            const signAction = this.getCurrentSignAction();
            if (signAction) {
                const canReturnDocument = signAction?.can_return ?? false;

                return this.showSignButton && canReturnDocument;
            }

            return false;
        },
        hasPermissionToReassignStaff() {
            return true; //new ApplicationsPermissions(this.currentUser).reassignApplication;
        },
        showAssignButton() {
            return this.record && !this.record?.assigned_staff_user_id && !this.isDocumentGenerated && this.record.status !== 'draft';
        },
        isDocumentGenerated() {
            return !!this.generateDocumentActions.some((el) => el.status === this.userActionsStatus.RESOLVED);
        },
        showReassignButton() {
            return this.record.internal_status !== 'rejected' && this.hasPermissionToReassignStaff && this.record?.assigned_staff_user_id && !this.isDocumentGenerated;
        },
        hasInputDocument() {
            return this.record?.has_internal_input_doc;
        },
    },
    methods: {
        ...mapActions({
            assign: "internalDocuments/form/assignStaff",
            approveDoc: "internalDocuments/form/approveDocument",
            rejectDoc: "internalDocuments/form/rejectDocument",
            signDoc: "internalDocuments/form/signDocument",
            doFindUserActions: "internalDocuments/form/findUserActions",
            doResetUserActions: "internalDocuments/form/doResetUserActions",
            doFind: "internalDocuments/form/find",
            resetUserActions: "internalDocuments/form/doResetUserActions",
            addComment: "internalDocuments/form/addComments",
        }),
        reassignStaff() {
            this.$refs.assignStaffModal.show();
        },
        returnDocument() {
            this.$alert({
                type: "warning",
                text: this.$t("INTERNAL_DOCUMENTS.VIEW.RETURN_DOCUMENT_MESSAGE"),
                confirmButtonText: this.$t("GENERAL.YES"),
                cancelButtonText: this.$t("GENERAL.NO"),
                hasConfirmation: true,
                confirmCallback: () => {
                    this.$refs.assignStaffModal.show(true);
                },
            });

        },
        approveDocument() {
            this.$alert({
                type: "warning",
                text: this.$t("INTERNAL_DOCUMENTS.VIEW.APPROVE_DOCUMENT_MESSAGE"),
                confirmButtonText: this.$t("GENERAL.YES"),
                cancelButtonText: this.$t("GENERAL.NO"),
                hasConfirmation: true,
                confirmCallback: () => {
                    this.approveInternalDocument();
                },
            });
        },
        rejectDocument() {
            this.$alert({
                type: "warning",
                text: this.$t("INTERNAL_DOCUMENTS.VIEW.REJECT_DOCUMENT_MESSAGE"),
                confirmButtonText: this.$t("GENERAL.YES"),
                cancelButtonText: this.$t("GENERAL.NO"),
                hasConfirmation: true,
                confirmCallback: () => {
                    this.rejectDoc(this.$route.params.id);
                },
            });
        },
        signDocument() {
            const currentSignAction = this.getCurrentSignAction();

            if (currentSignAction) {
                this.signLoading = true;

                this.signDoc({ id: this.$route.params.id, action: currentSignAction.id}).then(() => {
                    this.resetPolling();
                    this.$refs['confirm-sign-document-modal'].show();
                    this.signLoading = false;
                }).catch(() => {
                    this.signLoading = false;
                });
            } else {
                Message.error('INTERNAL_DOCUMENTS.VIEW.SIGN_DOCUMENT_ERROR');
            }
        },
        async processUserActions() {
            if (this.$route.params?.id) {
                try {
                    this.actionsLoading = true;
                    await this.getUserActions();
                    if (this.statusProcessingExists.length !== 0) {
                        this.actionsLoading = false;
                        this.resetPolling();

                        //get updated application data
                        await this.doFind(this.$route.params.id);
                    }
                } catch (error) {
                    this.resetPolling();
                    Message.error(error);
                }
            }
        },
        async syncUserActions() {
            try {
                this.actionsLoading = true;

                await this.getUserActions(true);
                if (this.isSignedByCurrentUser()) {
                    this.actionsLoading = false;
                    await this.doFind(this.$route.params.id);
                    this.resetPolling();
                }
            } catch (error) {
                this.resetPolling();
            }
        },
        async getUserActions(isSync = false) {
            try {
                const paramsResolved = {
                    query: `application_id eq ${this.record.id} and status eq ${this.userActionsStatus.RESOLVED}`,
                    sort: '-updated_date'
                };
                this.resolvedUserActions = await this.doFindUserActions(paramsResolved);

               if (!isSync) {
                    const paramsProcessing = {
                        query: `application_id eq ${this.record.id} and status eq ${this.userActionsStatus.PROCESSING}`,
                        sort: '-created_date'
                    };
                    this.statusProcessingExists = await this.doFindUserActions(paramsProcessing);
                }
            } catch(error) {
                if (this.polling) {
                    this.resetPolling();
                }
            }
        },
        async approveInternalDocument() {
            //send notification to user
            this.isApproveLoading = true;

            await this.approveDoc(this.$route.params.id);
            await this.doFind(this.$route.params.id);

            this.isApproveLoading = false;

            this.polling = setInterval(async () => {
                await this.processUserActions();
            }, 2000);

        },
        async rejectInternalDocument(data) {
            await this.rejectDoc(this.$route.params.id);

             //add comments to application
            if (data.comments) {
                await this.addComment({
                    id: this.internalDocumentId,
                    payload: {
                        comment: data.comments
                    }
                });
            }

            await this.doFind(this.$route.params.id);
        },
        internalDocumentSync() {
            if (!this.polling) {
                this.resetUserActions();
                this.polling = setInterval(async () => {
                    await this.syncUserActions();
                }, 2000);
            }
        },
        isSignedByCurrentUser() {
            if (!this.resolvedUserActions.length) return false;

            const resolvedSignActions = this.resolvedUserActions.filter((el) => el.action_type === this.userActionsTypes.SIGN_DOCUMENT);
            let isUserAssigned = false;
            let userHasFunction = false;

            resolvedSignActions.forEach((signAction) => {
                if (signAction?.user_id) {
                    isUserAssigned = signAction.user_id === this.currentUser.user_id;
                }

                if (signAction?.function_type) {
                    userHasFunction = !!this.accountDetails?.functions.includes(signAction.function_type);
                }
            });

            return isUserAssigned || userHasFunction;
        },
        getCurrentSignAction() {
            return this.signDocumentActions.find((el) => el.action_type === this.userActionsTypes.SIGN_DOCUMENT && el.status === this.userActionsStatus.PROCESSING);
        },
        async refreshActions(interval) {
            this.resetUserActions();

            this.polling = setInterval(async () => {
                await this.processUserActions();
            }, interval || 2000);
        },
        resetPolling() {
            clearInterval(this.polling);
            this.polling = null;
        }
    },
    async created() {
        //find user actions related to application
        this.doResetUserActions();
        await this.getUserActions();
        await this.refreshActions(500);
    },
    beforeDestroy () {
        if (this.polling) {
            this.resetPolling();
        }
    }
};
</script>
