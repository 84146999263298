import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import IdField from '@/shared/fields/id-field';
import DateTimeField from '@/shared/fields/date-time-field';
import EnumField from '@/shared/fields/enum-field';

const label = (name) => i18n.t(`INTERNAL_DOCUMENTS.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`INTERNAL_DOCUMENTS.PLACEHOLDERS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`INTERNAL_DOCUMENTS.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    created_by: new StringField('created_by', label('created_by'), {}, { placeholder: placeholder('created_by')}),
    file_type: new StringField('file_type', label('file_type'), {}),
    created_date: new DateTimeField('created_date', label('created_date')),
    status: new EnumField(
        'status',
        label('status'),
        [
            {
                value: 'generated',
                name: 'generated',
                text: enumLabel('status', 'generated'),
                type: 'info',
            },
            {
                value: 'draft',
                name: 'draft',
                text: enumLabel('status', 'draft'),
                type: 'dark',
            },
            {
                value: 'signed',
                name: 'signed',
                text: enumLabel('status', 'signed'),
                type: 'success',
            },
            {
                value: 'sealed',
                name: 'sealed',
                text: enumLabel('status', 'sealed'),
                type: 'success',
            },
            {
                value: 'resolved',
                name: 'resolved',
                text: enumLabel('status', 'resolved'),
                type: 'success',
            }
        ],
        {},
        {
            placeholder: placeholder('status'),
        }
    ),
    filename: new StringField('filename', label('filename'), {}),
};

export class DocumentModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
