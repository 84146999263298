<template>
    <div>
        <st-data-table
            headClass="st-data-table-head"
            :items="documents"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            @download="onDownload"
            responsive="sm"
            hover
        >
        </st-data-table>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { DocumentModel } from '@/modules/internal-documents/models/document-model';
    const { fields } = DocumentModel;

    export default {
        name: 'DocumentsListTable',
        data() {
            return {
                presenter: DocumentModel.presenter,
                files: [],
            };
        },
        computed: {
            ...mapGetters({
                rows: 'internalDocuments/form/filteredDocs',
                currentUser: 'auth/currentUser',
                fileToken: 'internalDocuments/form/fileToken',
                record: 'internalDocuments/form/record',
            }),
            fields() {
                return [
                    fields.created_by,
                    fields.filename,
                    fields.created_date,
                    fields.status.extendField({ labelType: true }),
                ]
            },
            actions(){
                return [{
                    name: 'download',
                    icon: 'download',
                    tooltipText: this.$t('GENERAL.ACTIONS.DOWNLOAD'),
                    customIcon: false,
                    type: 'primary'
                }];
            },
            documents() {
                return this.rows.map((el) => {
                    return {
                        ...el,
                        created_by: el.created_by ?? 'system'
                    }
                })
            }
        },
        methods: {
            ...mapActions({
                downloadDocument: 'internalDocuments/form/downloadDocument',
            }),
            onDownload(doc){
                window.open(`${window.VUE_APP_API_URL}/files/${doc.item.file_id}?token=${this.fileToken}`, '_blank');
            },
        },
    };
</script>
