<template>
    <st-page layout="tab" :showHeader="false">
        <internal-documents-activities
            :items="activities"
            :record="record"
        />
    </st-page>
</template>

<script>
import InternalDocumentsActivities from '@/modules/internal-documents/components/activities/InternalDocumentsActivities';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'InternalDocumentsActivityTab',
    components: {
        InternalDocumentsActivities,
    },
    props: {
        docId: String,
    },
    data() {
        return {
            polling: null,
        }
    },
    computed: {
        ...mapGetters({
            activities: 'internalDocuments/activity/activities',
            record: 'internalDocuments/form/record'
        }),
    },
    methods: {
        ...mapActions({
            getActivities: 'internalDocuments/activity/getActivities'
        }),
        updateActivities(docId) {
            this.getActivities(docId);
        }
    },
    async mounted() {
        if (this.record && this.record.id && !this.polling) {
            this.polling = setInterval(async () => {
                await this.getActivities(this.record.id);
            }, 5000);
        }
    },
    async created() {
        if (this.record && this.record.id) {
            await this.getActivities(this.record.id);
        }
    },
    beforeDestroy() {
        clearInterval(this.polling);
        this.polling = null;
    }
}
</script>
