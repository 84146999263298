<template>
    <st-filters-pagination
        stateModule="internalDocuments/form"
        :filters="filters"
        :total="totalDocs"
        @change="doFilter"
        :header="header"
        :moreFilters="false"
        :calcParams="calcInMemoryFilterFnParams"
    >
        <template #filters-toolbar>
            <slot name="toolbar"></slot>
        </template>

        <slot></slot>
    </st-filters-pagination>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';
    import { DocumentModel } from '@/modules/internal-documents/models/document-model';
    import calcInMemoryFilterFnParams from '@/shared/utils/calc-in-memory-filter-fn-params';

    const { fields } = DocumentModel;

    const filterSchema = new FilterSchema([
        fields.created_by,
        fields.status,
        fields.created_date
    ]);

    export default {
        name: 'DocumentsListFilter',
        data() {
            return {
                filters: filterSchema.fields,
                files: [],
                calcInMemoryFilterFnParams,
            };
        },
        computed: {
            ...mapGetters({
                totalDocs: 'internalDocuments/form/totalFilteredDocs',
                currentUser: 'auth/currentUser',
                fileToken: 'internalDocuments/form/fileToken',
                record: 'internalDocument/form/record'
            }),
            header() {
                return this.$t('INTERNAL_DOCUMENTS.LIST.DOCUMENTS_HEADER');
            },
        },
        methods: {
            ...mapActions({
                filterDocs: 'internalDocuments/form/filterDocs',
            }),
            doFilter(params) {
                this.filterDocs(params);
            },
        }
    };
</script>
